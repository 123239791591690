import React from 'react'
import './Play.css'

export default function Play() {
    return (
        <div>
            <section class="container2">
                <p>Under Construction. Come back soon!</p>
            </section>
        </div>
    )
}
