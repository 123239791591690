import React from 'react'
import './Mood.css'

export default function Mood() {
    return (
        <div>
            <section class="container2">
                <section class="constrict">
                    {/* <h2>Philosophy</h2>
                    <p>
                        Being revised
                    </p>
                    <br></br> */}
                    <h2>Motivation</h2>
                    <p>
                        Under Construction. Come back soon!
                    </p>
                    <br></br>
                    <h2>Inspiration</h2>
                    <p>
                        
                    </p>
                </section>
            </section>
        </div>
    )
}
